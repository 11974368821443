<template>
  <div class="bigBox">
    <div class="noticeTitle">
      {{noticeForm.title}}
    </div>
    <div class="noticeTime">
      {{noticeForm.createTime}}
    </div>
    <div class="noticeContent" v-html="noticeForm.content">
    </div>
  </div>
</template>
<script>
import {getNoticeDetail} from "@/api/desk/notice";
export default {
  data() {
    return {
      noticeForm:{
        id:''
      }
    }
  },
  mounted() {
    let {id} = this.$route.query;
    this.noticeForm.id = id;
    this.getDetail();
  },
  methods:{
    getDetail() {
      getNoticeDetail(this.noticeForm.id).then((res) =>{
        if (res.data.code == 200) {
          this.noticeForm = res.data.data;
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.bigBox {
  width: calc(100% - 80px);
  background-color: #FFFFFF;
  padding: 0 40px;
  height: 100%;
  overflow: auto;
}
.noticeTitle{
  width: 90%;
  margin: auto;
  text-align: center;
  font-size: 26px;
  color: #333333;
  padding: 42px 0 34px;
  font-weight: bold;
}
.noticeTime {
  width: 100%;
  height: 42px;
  line-height: 42px;
  color: #666666;
  font-size: 18px;
  border-bottom: 1px solid #afc4d1;
  margin-bottom: 26px;
}
</style>
